.empty-state-basic {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 400px;
}
.empty-state-basic img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
