//usual reset stuff
*,
*:before,
*:after,
ul,
li,
a,
button,
input,
h1,
h2,
h3,
h4,
h5,
h6,
p,
img,
image,
svg,
path,
g,
canvas {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: transparent;
  border: none;
  text-decoration: none;
  list-style: none;
}

@mixin size($width: inherit, $height: inherit) {
  width: $width;
  height: $height;
}

@mixin flex($vertical, $horizontal, $direction: row) {
  display: flex;
  align-items: $vertical;
  justify-content: $horizontal;
  flex-direction: $direction;
}

@mixin border-radius(
  $topLeft: 50%,
  $topRight: 50%,
  $botLeft: 50%,
  $botRight: 50%
) {
  border-top-left-radius: $topLeft;
  border-top-right-radius: $topRight;
  border-bottom-left-radius: $botLeft;
  border-bottom-right-radius: $botRight;
}

$deepBlue-5: #f1f3f4;
$deepBlue-10: #e4e6ea;
$deepBlue-20: #ccced5;
$deepBlue-40: #999eac;
$deepBlue-60: #666d82;
$deepBlue-80: #333d59;
$deepBlue-90: #192445;
$deepBlue-100: #000c2f;
$deepBlue-120: #000923;

$blue: #2e90eb;
$salmon: #f56b72;

$swipes-shadow-one: 0 1px 3px rgba(0, 0, 0, 0.06), 0 1px 2px rgba(0, 0, 0, 0.12);
$swipes-shadow-two: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
$swipes-shadow-three: 0 10px 20px rgba(0, 0, 0, 0.19),
  0 6px 6px rgba(0, 0, 0, 0.23);
$swipes-shadow-four: 0 14px 28px rgba(0, 0, 0, 0.25),
  0 10px 10px rgba(0, 0, 0, 0.22);
$swipes-shadow-five: 0 19px 38px rgba(0, 0, 0, 0.3),
  0 15px 12px rgba(0, 0, 0, 0.22);

.sw-loader__wrapper {
  position: relative;
  &--center {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .sw-loader__holder {
    @include size(70%, 70%);
    margin-left: 15%;
    margin-top: 15%;
    position: relative;
    transform: rotate(-45deg);
    .rect {
      @include size(100%, 100%);
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        to right,
        #3b3eee,
        #84acfc,
        #ead7a1,
        #8dd6ff,
        #78a3fa,
        #e687b8,
        #f4b35e,
        #f5919e,
        #fa95bc,
        #2d31ff,
        #7aa5fc
      );
      background-position: 0% 50%;
      background-size: 1000% 1000%;
      overflow: hidden;
      animation: moveGradient 15s infinite;
    }
  }
}

@keyframes moveGradient {
  to {
    background-position: 100% 50%;
  }
}
