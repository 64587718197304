#root {
  max-width: 1600px !important;
  margin: auto !important;
}
.flex_wrap--header {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  grid-column: 1/5;
}
.textbook-modal-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.textbook-modal-list li a {
  color: blue !important;
}
.textbook-modal-list ul {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* align-items: center; */
}
.textbook-modal-list ul span {
  padding: 5px 10px;
  margin: auto;
  align-self: center;
  color: black;
  font-weight: bold;
  border: 1px solid orange;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  width: 50px;
  background-color: rgba(255, 166, 0, 0.289);
}
.lucky-student {
  background: #00ff001c !important;
}
.signature_teacher {
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 90%;
  margin: auto;
  font-size: 12px;
  font-weight: 600;
  overflow: hidden;
}
.textbook-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 30px;
}
.textbook-card {
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  padding: 30px;
  background-color: white;
  border-radius: 20px;
  border: 2px solid #fff;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}
.textbook-card:hover {
  border: 2px solid #0176ee;
}
.textbook-card__title {
  font-size: 25px;
  font-weight: 500;
}
.textbook-card img {
  height: 100px;
  width: 100px;
}

.finished-student-file input[type="file"] {
  display: none;
}

.file-label {
  display: block;
  padding: 10px 20px;
  background-color: #e2e8f0;
  color: #fff;
  cursor: pointer;
  border-radius: 4px;
}

.file-label:hover {
  background-color: #cbd5e1;
}

.file-label:active {
  background-color: #94a3b8;
}

.salary__table--container header {
  z-index: 990;
}
.reset-password {
  color: blue;
  text-decoration: underline;
  margin-top: 20px;
  text-align: center;
}

.daily-finance-tables {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 30px;
}
.attendance-student-info:hover {
  color: #009688;
  text-decoration: underline;
}
.finished-students {
  grid-template-columns: 0.3fr 1fr repeat(6, 1fr);
}

.ielts-modal {
  min-width: 250px !important;
}

/* Reports age-group */

.reports-table {
  width: 100%;
  border-radius: 10px;
  overflow: hidden;
  padding: 5px !important;
  border-collapse: collapse;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.reports-table-head {
  background-color: #d946ef;
  color: white;
}
.reports-extra-table-head {
  background-color: #7c3aed;
  color: white;
}
.reports-table th,
.reports-table td,
.reports-table-footer td {
  padding: 10px;
  font-size: 15px;
  border: 1px solid #d1d5db;
}
.reports-table-body,
.reports-extra-table-body {
  background-color: white;
}
.reports-table-body:hover {
  background-color: #d8b4fe;
}

.reports-extra-table-body:hover {
  background-color: #c4b5fd !important;
}
.reports-table-body td,
.reports-extra-table-body td,
.reports-table-footer td {
  text-align: center;
}
.reports-table-footer {
  background-color: #f3f4f6;
  color: black;
  border-top: 2px solid #d946ef !important;
}
tbody .reports-table-body:nth-child(even) {
  background-color: #f3f4f6;
  color: #d946ef;
  border-top: 1px solid #9ca3af;
  border-bottom: 1px solid #9ca3af;
}
tbody .reports-extra-table-body:nth-child(even) {
  background-color: #f3f4f6;
  color: #7c3aed;
  border-top: 1px solid #9ca3af;
  border-bottom: 1px solid #9ca3af;
}
.attendance_input {
  width: 15px;
  height: 15px;
}
.transactions_history-empty {
  text-align: center;
  width: 100%;
  margin-top: 20px;
  /* color: rgb(238, 81, 81); */
}
.report-text-center {
  text-align: center;
}
.login-error-text {
  position: absolute;
}
.transfer-list--items {
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: salmon;
  color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.transfered-list--items {
  height: 100%;
  padding: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009688;
  color: white;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.exam_date-style {
  background-color: #fff !important;
}
.search_minWidth {
  width: 150px !important;
}
.exam-input-error {
  position: absolute !important;
  bottom: -10px !important;
}
.exam-input-module {
  margin: 0 !important;
  height: 45px;
  border-radius: 5px !important;
  margin: 5px 0 !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}
.excel-menu--item {
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.excel-menu--item p {
  font-weight: 500;
}
.export-excel {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid #166534;
  border-radius: 5px;
  background-color: #15803d;
  height: 35px;
  width: 100px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
}
.export-excel:hover {
  background-color: #14532d;
}
.export-excel h1 {
  color: white !important;
  padding-top: 0 !important;
}
.info-module {
  border: 1px solid #bfdbfe;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 0 20px;
  min-width: 100px;
  background-color: #eff6ff;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.info-module h1,
.export-excel h1 {
  color: #000;
  font-family: "Inter";
  font-size: 13px;
  display: flex;
  align-items: center;
  height: 100%;
  padding-top: 2px;
  font-weight: 500;
}
.basic-single {
  z-index: 0;
}
.checkbox-label-billing {
  font-size: 10px !important;
}
.home-logo {
  height: 45px;
}
.home-logo__img {
  object-fit: scale-down;
  width: 100%;
  height: 100%;
}
.students-select {
  width: 100%;
  z-index: 999 !important;
}

/* BarChart */

.bar-chart {
  margin-top: 20px;
  padding: 10px;
  background-color: #ffffff;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.add-staff-section {
  position: relative;
  width: 100%;
}
/* Trello */

.react-trello-board {
  background-image: url("/public/images/trello-bg.jpg");
}
.react-trello-card header {
  border-bottom: none;
  margin-bottom: 0;
}
.react-trello-card div {
  border-top: none;
}
.trello-input {
  background: #ffffff;
  border-radius: 8px;
  width: 100%;
  border: 1px solid #cccccc;
  outline: none;
  margin: 8px 0 15px 0;
  font-size: 13px;
  line-height: 15px;
  white-space: nowrap;
  padding: 10px !important;
}
.trello-input:focus {
  border: 2px solid #087bf0;
}
.trello-error-massage {
  color: tomato !important;
}
.trello-modal {
  width: 40%;
}
.trello-img {
  object-fit: scale-down;
}
.trello-table {
  margin: 20px 0;
}

/* Salarytable */

.salary__table {
  border-radius: 10px !important;
  -webkit-border-radius: 10px !important;
  -moz-border-radius: 10px !important;
  -ms-border-radius: 10px !important;
  -o-border-radius: 10px !important;
}
.salary-table__input {
  width: 100%;
  height: 30px;
  padding: 10px;
  background-color: #fcfcfc;
  border: 1px solid #f2f2f2;
  outline: none;
  text-align: center;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.salary-table__input:focus {
  border: 1px solid #cccccc;
}
.salary__table--container {
  margin-bottom: 20px;
  overflow: hidden;
}
.salary__table--container header {
  border-top-right-radius: 15px !important;
  border-top-left-radius: 15px !important;
}
.salary-table__item {
  display: flex;
  align-self: center;
}

/* EstimatedEmployeeSalary */

.estimated-salary--grid {
  gap: 0 20px;
  text-align: center !important;
  width: 100%;
  grid-template-columns: 0.05fr repeat(3, 0.5fr) repeat(7, 0.7fr) 0.4fr;
}

/* SalaryAdvance */

.empty-text {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: slategrey;
  text-align: center;
}
.payment-history {
  width: 60%;
  margin: auto;
  height: min-content;
}
.direction-salary {
  flex-direction: column !important;
  align-items: flex-start !important;
}
.confirmation__title {
  font-size: 15px;
}
.confirmation--modal {
  width: min-content;
}
.salary-input--container {
  width: auto;
  display: flex;
  gap: 20px;
  align-items: center;
}
.salary-price {
  font-size: 11px !important;
}
.expenditure__container {
  overflow-x: initial;
}
.salary-transfer {
  min-height: 0 !important;
  padding-bottom: 0 !important;
}
.salary-advance__item {
  border-radius: 5px;
  background-color: #fcfcfc;
  border: 1px solid #f2f2f2;
  padding: 15px !important;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.salary-advance__search {
  display: flex;
  justify-content: center;
  width: 400px;
  margin: auto;
}
.salary-advance {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}
.salary-advance__select {
  max-width: 400px;
  width: 100%;
}

/* ExpentitureLists */

.expenditure-expired--color {
  color: gold !important;
  font-weight: 600;
}
.fee--green {
  color: yellowgreen;
  font-weight: 600;
}
.debt--color {
  color: orangered;
  font-weight: 600;
}
.staff-details--color {
  font-weight: 600;
  color: #1976d2;
}
.total-expenditure:last-child {
  background: #1976d2 !important;
  color: white !important;
  font-weight: bold;
}
.expenditure-list--grid-with_commit {
  display: grid;
  grid-template-columns: 0.05fr 1fr 0.5fr 0.7fr 0.8fr 0.8fr 0.8fr 0.5fr 1fr 0.1fr;
  grid: 0 20px;
  text-align: center !important;
  width: 100%;
}
.expenditure-list--grid {
  display: grid;
  grid-template-columns: 0.05fr 1fr 0.5fr 0.7fr 0.8fr 0.8fr 0.8fr 0.5fr 0.1fr;
  grid: 0 20px;
  text-align: center !important;
  width: 100%;
}

/* FixedCosts */

.expendeture--attached_employee {
  width: 100% !important;
}
.expenditure-input {
  width: 40%;
  height: 40px;
  font-size: 15px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  padding: 15px 16px;
  outline: none;
  background-color: #fff;
  border: 1px solid #ccc;
}
.expenditure-input:focus {
  border: 2px solid #0176ee !important;
}
.payment-expenditure--space {
  display: flex;
  align-items: center;
  justify-content: flex-start !important;
  gap: 20px 30px;
}
.fixed-costs {
  width: 100%;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  gap: 20px 0;
}
.expendeture-select {
  width: 50%;
}

/* Students Billing */

.title--background {
  background-color: #ffffff;
  padding: 0px 15px;
  border-radius: 12px;
  height: 40px;
  display: flex;
  align-items: center;
  align-self: center;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  margin-right: 24px;
  -o-border-radius: 12px;
}
.debet-title {
  font-size: 15px;
  font-weight: 500;
  color: #009688;
}
.debet-title span {
  color: orange;
  margin: 0 5px;
}
.debet-title:first-child {
  margin-right: 25px;
}
.debet--grid {
  grid-template-columns: 0.05fr 0.7fr 0.5fr repeat(3, 0.5fr) 0.7fr 0.7fr 0.5fr;
  gap: 0 20px;
  text-align: center !important;
  width: 100%;
}

/* Student payment transaction */

.payment--grid {
  grid-template-columns: 0.05fr 0.7fr 0.5fr repeat(3, 0.5fr) 0.7fr 0.7fr 0.4fr 0.4fr 0.4fr 0.2fr;
  gap: 0 20px;
  text-align: center !important;
  width: 100%;
}
.income-reports--grid {
  grid-template-columns: 0.05fr 0.7fr 0.5fr repeat(3, 0.5fr) 0.7fr 0.7fr 0.4fr 0.4fr 0.4fr;
  gap: 0 20px;
  text-align: center !important;
  width: 100%;
}

.payment-datepicker {
  background-color: #fff !important;
  border: 1px solid #cccccc;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  border: none !important;
}
.student-registr--date {
  border: 1px solid #cccccc;
}
.payment-datepicker:focus {
  border: 2px solid #0176ee;
}
.date_clear-btn {
  margin: 0 !important;
  padding: 0 5px !important;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: #216ba5 !important;
}
.react-datepicker__input-container input {
  padding: 0 10px !important;
}

/* InComing Cash */

.income-input {
  font-size: 13px !important;
}
.number--payment {
  width: 20px !important;
}
.history-tablehead {
  font-weight: 600 !important;
}
.print__check--disabled {
  display: none !important;
}
.income__detail--title {
  font-size: 13px !important;
  font-weight: 500;
  color: #0176ee;
}
.income__debt--color {
  color: orange;
}
.income__students-lists {
  position: relative !important;
  top: 0 !important;
  box-shadow: none !important;
}
.income-searchbar {
  position: relative;
  width: 50%;
  display: flex;
  align-items: center !important;
  min-height: 74px;
}
.icome-student_short_lists {
  position: absolute;
  top: 17px;
  right: 20px;
  z-index: 1;
  border: 1px solid #ccc;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  min-height: 40px;
  max-width: 300px;
  overflow: hidden;
}
.search-id_input {
  width: 300px;
  height: 40px;
  border: none;
}
.search-id__wrapper {
  align-items: center;
}
.search-id_input:focus {
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
    rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
}
.cash-disount__details-item {
  width: 25%;
  text-align: center;
  height: 100%;
}
.cash-disount__details {
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
}
.cash-disount__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100% !important;
  max-width: 100%;
  height: 100%;
  padding: 10px 15px;
  border: 1px solid #cccccc;
  border-top: none;
  cursor: pointer;
}
.cash-disount__item:hover {
  background-color: rgba(224, 224, 224, 0.37);
}
.cash-disount__list {
  min-width: 100%;
  margin: 0;
}
.cash-discount {
  width: 100%;
  border-top: 1px solid #cccccc;
  overflow: auto;
  max-height: 360px;
}

.payment-place {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.payment-input {
  margin: 0 10px 0 0;
  width: 40%;
}
.payment-place--space {
  margin-top: 10px !important;
}

/* StudentsRegistration */

.comment-field {
  position: relative;
}
.comment-field:hover .comment-container {
  display: block;
}
.comment-container {
  position: absolute;
  padding: 10px;
  background-color: white;
  display: none;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0px 0px 3px black;
  border-radius: 4px;
  z-index: 99;
  min-width: 100px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
}
.attendanceDiscountModal {
  min-width: 545px;
}
.discountContainer--box {
  margin-top: 20px;
  padding-top: 30px !important;
}
.discount-modal-header {
  font-weight: 600;
  font-size: 14px;
  color: #ffffff;
  background: #429ffe;
  border-radius: 8px;
  padding: 8px 15px;
  height: 35px;
  width: fit-content !important;
  position: absolute;
  top: 22px;
  left: 10%;
  z-index: 999 !important;
}
.checked-radio {
  border: 1px solid #2684ff !important;
}
.student--dicount_place {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
  margin-top: 0 !important;
}
.registir-discount__type {
  width: 48%;
  display: flex;
  align-items: flex-start;
  padding: 10px 10px 0 0;
  border-radius: 5px;
  border: 1px solid #ccc;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.registir-discount__item {
  width: -webkit-fill-available;
}
.back--group {
  display: flex;
}
.back--group p {
  font-size: 15px;
  color: #009688;
}

/* Profile */

.noneFocus:focus {
  box-shadow: none !important;
  border: 1px solid #ccc !important;
}
.profileDataTable {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #ccc;
  margin: 10px 0;
}
.staffPay {
  display: flex;
  justify-content: space-between;
}
.textBookTitle {
  font-size: 15px;
  font-weight: 600;
  margin: 10px 0;
}
.textbooks {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}
.personalInfo {
  display: grid;
  color: #41424d;
  grid-template-columns: 1fr;
  gap: 20px;
  padding: 10px;
  border-top-right-radius: 10px;
  background: #fff;
}
.personalInfo .transfer-infos {
  font-size: 12px;
}
.profile-photo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100px;
}
.profile-photo-container input[type="file"] {
  display: none;
}
.isUploading {
  opacity: 0.5;
}
.uploadPhoto {
  color: #017524;
  font-size: 12px;
  padding: 2px 0;
  width: 100%;
  text-align: center;
}
.profile-photo {
  width: 100px;
  border: 2px solid #929090;
  height: 100px;
  object-fit: cover;
  background-color: #f1f1f1;
  border-radius: 50%;
  cursor: pointer;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.inputLabel {
  font-size: 13px;
}
.profileContainer {
  position: sticky;
  width: 100%;
  max-width: 1600px;
  margin: auto;
  border-radius: 10px;
  min-height: calc(90vh - 50px);
  margin-top: 10px !important;
  display: flex;
  justify-content: space-between;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.profileLeft {
  width: 75%;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: auto;
  background-color: #fff !important;
}
.profileRight {
  width: 25%;
  border-left: 2px solid #c9c9c9;
  height: auto;
}
.passwordContainer {
  padding: 10px;
}

/* StaffsRegistration */

.get--employee {
  max-width: 630px;
}
.badStaff {
  background-color: rgb(255, 247, 236);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
.deleteButton {
  margin-right: 10px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 4px;
  background: rgba(224, 224, 224, 0.747);
  visibility: hidden;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -ms-border-radius: 4px;
  -o-border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: 2px dashed #75757588;
}
.fileUpload {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0;
}
.file-upload-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  margin: 5px 0;
}
.file-upload-container:hover .deleteButton {
  visibility: visible !important;
}

.file-input-label {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  padding: 10px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  margin-right: 10px;
  cursor: pointer;
}

.file-input {
  display: none;
}

.file-input-icon {
  margin-right: 10px;
}

.file-name {
  margin-left: 5px;
}

.file-input-text {
  font-weight: bold;
}

.button {
  margin-top: 10px;
}

/* StudentTrasnfer */

.transfer-student-age {
  padding-right: 20px;
}
.empty-state {
  width: 100%;
  height: 45vh;
  display: flex;
  justify-content: center;
}
.empty-state img {
  /* mix-blend-mode: darken; */
  width: 100%;
  object-fit: scale-down;
}
.transfer-student-select {
  display: flex;
  flex-direction: column;
  width: 50%;
}
.transfer-select {
  font-size: 12px;
  margin-top: 10px;
  margin-bottom: 0;
}
.transfer-search-container {
  position: relative;
}
.students_list-transfer {
  position: absolute;
  box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px,
    rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;
  background-color: #fff;
  z-index: 1;
  top: 40px;
  left: 0;
  right: 0;
  border: 0;
}
.selected-option {
  background: rgba(17, 17, 26, 0.1);
}
.students_list-transfer li {
  cursor: pointer;
  padding: 5px 10px;
  font-size: 11px;
  display: flex;
  align-items: center;
}
.students_list-transfer li:hover {
  background-color: rgba(17, 17, 26, 0.1);
}
.transfer-input {
  outline: none;
}
.student-details-group {
  padding: 0;
}

/* GroupAttendanceDivision */

.attendance-table li {
  text-align: center;
}
.normal-select-bar {
  width: fit-content;
  position: relative;
  z-index: 99 !important;
}

/* GroupLevelUP */

.levelUp-teachers {
  display: flex;
  flex-direction: column;
}
.level-teacher {
  position: relative;
}
.levelUp-teachers {
  border: 1px solid red;
}
.level-teacher:hover .attendance-cards {
  display: flex !important;
  border: 1px solid #cccccc;
  background-color: rgb(225, 237, 252);
}
.levelUp p {
  width: max-content !important;
  margin: 3px 0;
  height: 100%;
}
.levelUp p span {
  margin: 0 5px;
}
.level-teacher {
  height: 100%;
  display: flex;
  align-items: center;
}
/* RoomAvailability */

.room_availability_empty {
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
}
.room-title {
  width: 250px;
}
.week-days-title {
  height: 100%;
}
.week-days {
  min-width: 200px;
}
.for-contain {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.for-contain span {
  width: 120px;
}

/* ExamDetails */

.passing-scores__item label {
  font-size: 14px;
}
.passing-scores__item input[type="number"] {
  height: 25px !important;
  width: 40px;
  outline: none;
  margin: 0 10px;
  border-radius: 5px;
  padding: 0 5px;
  font-family: "Inter";
  font-weight: 500;
  font-size: 13px;
  border: 2px solid #cccccc !important;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.passing-scores__item {
  height: 100%;
  display: flex;
  align-items: center;
}
.passing-scores {
  display: flex;
  align-items: center;
}
.exam_detail_select {
  height: 30px;
  font-size: 12px;
  padding: 5px !important;
  margin: 0 !important;
  width: 100%;
  outline: none;
  border: 1px solid #cccccc;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.score_field {
  width: 150px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.button_position_container {
  position: relative !important;
  min-height: 40px;
}
.submit_btn_position {
  position: sticky;
  right: 15px;
  min-width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.student_exam_score {
  height: 100%;
  width: 100%;
  outline: none;
  padding: 0 5px;
  font-family: "Inter";
  font-weight: 600;
  font-size: 12px;
  text-align: center;
  color: #fff;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  -moz-transition: all 0.2s ease-in;
  -ms-transition: all 0.2s ease-in;
  -o-transition: all 0.2s ease-in;
}
.student_exam_score:hover,
.student_exam_score:focus {
  background: rgb(233, 253, 230);
  z-index: 2;
  color: #494949;
}
.studentScore {
  position: absolute;
  padding: 3px;
  background: #017524 !important;
  color: #fff;
  pointer-events: none;
  min-width: 20px;
  font-size: 12px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
  line-height: normal;
}
.exam_details_heading {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}
.exam_max_score {
  height: 100%;
  width: 100%;
  min-height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px 0;
}
.max_score_field {
  height: 25px !important;
  width: 40px;
  outline: none;
  margin: 0 10px;
  border-radius: 5px;
  padding: 0 5px;
  font-family: "Inter";
  font-weight: 400;
  font-size: 13px;
  box-shadow: 0 0 1px #cccccc !important;
  border: 1px solid #cccccc !important;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.max_score_field:focus,
.passing-scores__item input[type="number"]:focus {
  box-shadow: 0 0 1px #2684ff !important;
  border: 2px solid #2684ff !important;
}
.exam_max_score p {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter" !important;
}

/* Take an exam */

.attendanceListSelect {
  width: 100%;
  height: 100%;
  margin: 0 !important;
  background-color: #f6f6f6;
  color: #323233;
  padding: 5px !important;
  border-radius: 5px !important;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  -ms-border-radius: 5px !important;
  -o-border-radius: 5px !important;
}
.exam_items {
  width: 100%;
  padding: 15px 18px !important;
  border-radius: 15px;
  background: #fff;
  margin: 10px 0;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
}
.search_bar_form {
  box-sizing: border-box !important;
  position: relative;
  outline: none;
}
.search_bar {
  padding-left: 35px !important;
  margin: 0 !important;
  border-radius: 4px !important;
  -webkit-border-radius: 4px !important;
  -moz-border-radius: 4px !important;
  -ms-border-radius: 4px !important;
  -o-border-radius: 4px !important;
  font-family: "Inter" !important;
}
.search_bar:focus,
.manage_group_select:focus {
  box-sizing: border-box !important;
  box-shadow: 0 0 1px #2684ff !important;
  border: 1px solid #2684ff !important;
  border-width: 2px !important;
}
.select__menu {
  z-index: 999 !important;
}
.search_bar_form img {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.select_bar {
  max-width: 140px;
  z-index: 99 !important;
}
.exam_input_field {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  height: 40px;
  gap: 20px;
  min-width: 400px;
  width: max-content;
}
.exam_heading {
  display: flex;
  flex-direction: column;
}
.exam_table_head {
  width: 100%;
  display: grid;
  align-items: center;
  grid-template-columns: 0.05fr 0.3fr 0.3fr 0.3fr 0.6fr 0.6fr repeat(4, 0.4fr) 0.08fr;
  gap: 0 20px;
  position: relative;
}
.exam_head_item {
  height: fit-content;
  text-align: center;
  font-size: 13px;
}
.exam_head_item a {
  color: #0176ee;
}
.exam_head_item a:hover {
  text-decoration: underline;
}

/* Manege Group  */

.tab_panel {
  width: 90% !important;
}
.css-19kzrtu {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.manage_group_select {
  width: 100%;
}
.select_label_group {
  font-family: "Inter";
  font-size: 15px;
  color: #494949;
  margin: 5px 0;
}

/* Attendance */

.for-attendance-cards:hover .attendance-cards__none {
  display: none !important;
}
.next--contact {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.next--contact input[type="date"] {
  width: 50%;
}
.next--contact label {
  width: auto;
}
.esseyCheckBox {
  display: flex !important;
  flex-wrap: wrap !important;
  justify-content: center !important;
  align-items: center;
  gap: 0px 15px;
  padding: 5px 0 !important;
}
.attendanceDetailsHeader {
  display: flex !important;
  align-items: center;
}
.student-expelled {
  background-color: rgba(255, 0, 0, 0.144);
  height: 100%;
}
.commentRemoveStudent {
  background-color: #fff;
  width: 100%;
  resize: none;
  outline: #429ffe;
  min-height: 100px;
}

.desk-fname {
  padding: 8px 20px 8px 0px !important;
  position: relative;
  cursor: pointer;
  width: 100%;
}

.desk-fname .deleteStudentent {
  position: absolute;
  right: 5px;
  top: 55%;
  transform: translateY(-50%);
  display: none;
}
.desk-fname .deleteStudentent img {
  height: 20px;
  width: 20px;
}
.desk-fname:hover .deleteStudentent {
  display: block;
}
.discountBtns {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;
  margin-bottom: 5px;
}
.saveBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 87px;
  height: 30px;
  background: linear-gradient(90.65deg, #419efe 0%, #087bf0 100%);
  border-radius: 6.4px;
  -webkit-border-radius: 6.4px;
  -moz-border-radius: 6.4px;
  -ms-border-radius: 6.4px;
  -o-border-radius: 6.4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  margin: 0 10px;
  padding: 0 10px;
}
.saveBtn:hover {
  background: linear-gradient(90.65deg, #66d8ff 0%, #5facff 100%);
}
.cancelBtn:hover {
  background: linear-gradient(274.14deg, #d5d5d5 0%, #e9eaeb 100%);
}
.cancelBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 99px;
  height: 30px;
  background-color: #f2f2f2;
  border-radius: 6.4px;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #060607;
  margin: 0;
  padding: 0 10px;
}
.discountTitle {
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  color: #323232;
  text-align: center;
  margin-bottom: 20px;
}
.discountContainer {
  width: 100%;
  min-width: 400px;
  border-radius: 10px;
  background: #f2f2ff;
  padding: 10px 20px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border: 1px solid #429ffe;
}
.attendanceDiscountModal {
  position: fixed;
  box-sizing: border-box;
  width: auto;
  z-index: 1;
  top: 50%;
  left: 50%;
  padding: 20px 30px;
  transform: translate(-50%, -50%);
  background: #fff;
  border-radius: 10px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.discountInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 10px 0;
}
.discountInput label {
  font-size: 14px;
  font-weight: 400;
  margin-right: 20px;
}
.discountInput input[type="number"] {
  width: 60px;
  text-align: center;
  border-radius: 5px;
  padding: 6px 7px;
  border: 1px solid #cccccc;
  outline: none;
  font-size: 12px;
  background-color: #ffffff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}
.error-ielts-input {
  border: 1px solid red !important;
}
.discountInput input[type="number"]:focus {
  border: 1px solid #0176ee !important;
  background-color: #fff;
}
.lessonStyleBtn:hover {
  text-decoration: underline;
  color: #0176ee;
  cursor: pointer;
}
.stu-num {
  width: 40px !important;
}
.stu-fullname {
  width: 200px !important;
}
.stu-idnumber {
  width: 80px !important;
}
.stu-age {
  width: 70px !important;
}
.payment-status {
  width: 70px;
}
.desk-exam-config {
  display: flex;
  font-size: 12px;
  width: fit-content;
  min-height: 50px;
}
.desk-exam-config .desk-config__item {
  padding: 0 !important;
}
.date-lesson {
  width: 100px !important;
}
.desk-week-lesson_1 {
  width: 100%;
}
.desk-week {
  padding: 0;
  display: flex;
  flex-direction: column;
}
.desk-week-text_one {
  height: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e2e3e3;
  border-left: 1px solid #e2e3e3;
}
.dateContainerHeader {
  width: 100%;
  display: flex;
}
.attendance_detail_img {
  width: 25px;
}
.next_prev_btns {
  min-width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.next_prev_btns button {
  width: 120px;
  padding: 10px !important;
  font-size: 13px !important;
}
.attendance-cards-list {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  margin: 2px 0;
}
.attendance-cards-list p {
  width: max-content;
  margin-left: 3px;
}
.attendance-cards {
  display: none;
  list-style: none;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  left: 100%;
  top: 0;
  pointer-events: none;
  min-width: 150px;
  z-index: 1;
  background-color: #fff;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #0176ee;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.for-attendance-cards:hover .attendance-cards {
  display: flex;
}
.card_icon_journal {
  height: 49%;
  display: flex;
  align-items: center;
  font-family: "Inter";
  font-size: 12px;
  margin: 0px 2px;
}
.card_icon_journal img {
  width: 18px !important;
  height: 18px !important;
  margin-right: 1px;
}
.attendance_cards {
  padding: 2px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.counter_coast {
  display: flex;
  align-items: center;
  margin: 0 10px;
}
.counter_coast span {
  font-size: 15px;
  margin: 0 5px;
}
.detail_checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.modal-is_missed {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fcfcfc;
  border: 1px solid #429ffe;
  border-radius: 4px;
  padding: 16px;
  display: none;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}
.card_types {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: fit-content;
}
.card {
  width: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.current_date {
  color: #01ee48;
}
.passed_date {
  color: #0176ee;
}
.exam_list {
  width: 300px;
  display: flex;
  justify-content: space-between;
}
.desk-exam {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.desk_exam_type {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 35px;
  border-bottom: none !important;
  border-top: none !important;
}
.exam_date {
  width: 100% !important;
  height: 35px;
}
.date_exam_title {
  height: 34px !important;
  display: flex;
  align-items: center;
}
.cash_btn {
  height: 45px !important;
  padding: 0 !important;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* StudentAttachment */

.fsBPnz {
  min-width: 30px !important;
}
.gfYVEd {
  max-width: 100px !important;
}
.group_modal_icon h4 {
  font-size: 16px;
  font-weight: 500;
}
.group_sub_header {
  position: relative;
  max-height: 1000px;
  /* transition: max-height 0.4s ease-in-out; */
  margin-bottom: 10px;
  border: 1px solid #cccccc;
  border-radius: 10px;
  padding: 0 20px;
  background: #fff;
  overflow: auto;
}
.group_modal_icon {
  position: absolute;
  top: 2px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  right: 0;
  padding: 10px 20px !important;
}
.group_modal_hidden {
  max-height: 50px;
  overflow: hidden;
}
.sub_header {
  display: flex;
  gap: 10px 20px;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
}
.table_search:focus {
  box-shadow: 0 0 5px rgba(25, 118, 210, 0.5);
  border: 1px solid rgba(25, 118, 210, 0.5);
}
.table_search::placeholder {
  font-weight: 500;
  color: #1976d2;
  text-transform: uppercase;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
}
.table_search {
  height: 36px;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
  font-size: 0.8125rem;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  border-radius: 4px;
  outline: none;
  border: 1px solid rgba(25, 118, 210, 0.5);
  color: #1976d2;
  font-size: 13px;
  padding: 0 10px;
}
.submit_btn_group_student {
  width: 100%;
  display: flex;
  justify-content: end;
  margin-top: 20px;
}
.data_table_block {
  width: 49.5%;
}
.data_table {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
}
.table_container {
  width: 100%;
  padding: 0 25px;
  margin-top: 20px;
}

/* Group Page */

.css-1k23hlb-MuiButtonBase-root-MuiButton-root {
  color: #3b9afd !important;
  border: 1px solid #3b9afd !important;
}
.css-11qr2p8-MuiButtonBase-root-MuiButton-root {
  background-color: #3b9afd !important;
  box-shadow: 0px 4px 10px rgb(59 154 253 / 20%) !important;
}
.css-1rwt2y5-MuiButtonBase-root-MuiButton-root {
  color: #3b9afd !important;
  border: 1px solid #3b9afd !important;
}
.margin_row {
  margin-bottom: 10px !important;
}
.group_modal_title {
  top: 20px !important;
  left: 5% !important;
}
.modal_add_btn {
  width: 100%;
  margin-top: -10px !important;
  margin-bottom: 10px;
  display: flex;
  justify-content: center;
}
.modal_delete_btn {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0px 0 15px 0;
}
.group_modal {
  max-height: 490px !important;
  overflow-y: scroll !important;
}

.MuiMenuItem-root {
  font-size: 15px !important;
}
.MuiMenu-list {
  border: 1px solid #429ffe;
  border-radius: 4px;
}
.MuiMenu-root {
  top: 20px !important;
}
.students_grid {
  grid-template-columns: 1fr 1fr 2.25fr !important;
}

/* React Draft Wysiwyg */

.rdw-editor-toolbar {
  border-radius: 8px !important;
  margin-top: 5px;
}
.rdw-editor-main {
  background-color: #fff;
  padding: 0 10px;
  min-height: 200px;
  max-height: 280px;
  overflow: scroll;
  border-radius: 8px;
}

.branch_modal_icon {
  position: absolute;
  top: 7px;
  cursor: pointer;
  right: 30px;
}
.course_field_width_control {
  width: 100%;
}
.react-datepicker-wrapper,
.react-datepicker__input-container {
  display: block !important;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  background: #f6f6f6;
  border: 1px solid #cccccc;
  border-radius: 4px;
  cursor: pointer;
  position: relative;
}
.react-datepicker__input-container input {
  font-size: 13px;
  border-radius: 4px;
  line-height: 16px;
  height: 100%;
  outline: none;
  padding: 0px 0px 0px 10px;
}
.get_above {
  position: absolute !important;
  top: 20px !important;
  left: 5% !important;
  z-index: 99;
}
.students_modal_scroll {
  overflow-y: auto !important;
  max-height: 505px !important;
}
.connection {
  display: flex;
  font-size: 15px !important;
}
.connection p {
  color: #eda202;
}
.student_modal {
  width: 90% !important;
}
.checkBox_style {
  border: 1px solid #cccccc;
  border-radius: 8px;
  background-color: #fff;
  height: 40px;
  margin-top: 8px;
  display: flex;
  justify-content: space-evenly;
}
.student_modal_checkbox {
  display: flex;
}
.student_modal_checkbox input {
  margin-right: 5px;
}
.remove_staff_modal {
  width: 100% !important;
}
.lst_btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.remove_icon {
  display: flex;
  font-size: 15px !important;
}
.remove_icon p {
  color: #eda202 !important;
}
.staff_menu {
  width: max-content !important;
}
.auto_select {
  padding: 0 !important;
  margin-top: 18px !important ;
  margin-bottom: -3px !important;
  min-height: 30px !important;
}
.css-2613qy-menu {
  z-index: 999 !important;
}
/* Multiselect */

.multiselect {
  padding: 0px !important;
  border: none !important;
  width: 100% !important;
}
.css-t3ipsp-control {
  border-radius: 8px !important;
  padding: 0px !important;
  box-shadow: 0 0 5px rgba(81, 203, 238, 1) !important;
  border: 1px solid rgba(81, 203, 238, 1) !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.css-13cymwt-control {
  border-radius: 8px !important;
  margin: 0 !important;
  padding: 0px !important;
  -webkit-border-radius: 8px !important;
  -moz-border-radius: 8px !important;
  -ms-border-radius: 8px !important;
  -o-border-radius: 8px !important;
}
.css-14el2xx-placeholder {
  display: none !important;
}
/* Switch */

.switch_section {
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 10px;
}
.switch_section p {
  font-size: 14px;
  margin-right: 10px;
}
.back_btn_modal {
  position: absolute;
  top: 20px;
  left: 50px;
  overflow: hidden;
  background-color: #fff;
  border-radius: 50%;
}
.btn_container {
  background: #377ca327 !important;
  box-shadow: 0 0 5px #2192ee10;
}
.modal-input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
}
.view-input:focus {
  box-shadow: 0 0 5px rgba(81, 203, 238, 1);
  border: 1px solid rgba(81, 203, 238, 1);
  outline: none !important;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.remove_scroll {
  overflow-y: clip !important;
  padding-bottom: 100px;
  min-height: 700px !important;
}

/* Input Error */
.input-error-message {
  font-size: 13px !important;
  color: rgb(238, 81, 81) !important;
}

.modal_error_value {
  position: absolute;
  font-size: 12px;
  bottom: 0px;
}
.modal_rooms_error {
  position: absolute;
  font-size: 12px;
  bottom: -15px !important;
}
.error_input {
  box-shadow: 0 0 5px rgb(238, 81, 81) !important;
  border: 1px solid rgb(238, 81, 81) !important;
}
.passport_error {
  display: flex;
  flex-direction: column;
}
.d-none {
  display: none;
}
.none-create-btn {
  display: none !important;
}
.d-block {
  display: block;
}
.d-flex {
  display: flex;
}
.c-disabled {
  background: rgb(221, 217, 217) !important;
  pointer-events: none;
}
.category_opacity {
  opacity: 0.7 !important;
}
.red-background {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255, 42, 42);
}
.blue_background {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(81, 203, 238, 1);
}
.orange_background {
  width: 140px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eda202;
}
.red-border {
  border: 1px solid rgb(255, 42, 42);
}
.blue_border {
  border: 1px solid rgba(81, 203, 238, 1);
}
.orange_border {
  border: 1px solid #eda202;
}

.c-pointer {
  cursor: pointer;
}

.c-none {
  cursor: none;
}
.back-button-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: -15px;
}
.search-box {
  padding: 8px 12px 8px 30px;
  height: 35px !important;
  background: #f6f6f6;
  border: 1px solid #cccccc;
  border-radius: 4px;
  font-size: 12px;
}
.page_title {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title_bar_item {
  display: flex;
  align-items: center;
  margin: 0 !important;
  margin-right: 25px !important;
}
.title_bar_btns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important;
}
.back_btn {
  color: black;
  background: #2192ee10 !important;
  margin-left: 28px !important;
  font-size: 18px;
}
.back_icon {
  font-size: 25px !important;
}
.expeniture_modal {
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  place-items: center;
  transition: all 0.3s;
}
.active_modal {
  display: grid;
}
.scroll_none {
  overflow-x: unset !important;
}

/* Loader */

.basic_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  z-index: 100;
  background: rgba(256, 254, 254, 0.9);
}
.option_loader {
  position: absolute;
  top: 34%;
  left: 0;
  right: 0;
  width: 99%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 35px;
  margin: 0 1px !important;
  z-index: 100;
  border-radius: 8px;
  background: rgba(256, 254, 254, 1);
}
.stage_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  border-radius: 10px;
  z-index: 100;
  background: rgba(256, 254, 254, 0.9);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}
.stage_tabs_loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 100;
  background: rgba(256, 254, 254, 0.8);
}
.relativeLoader {
  position: relative !important;
  background: none !important;
  min-height: 250px !important;
}
.reports_btns {
  display: flex;
  align-items: center;
  gap: 15px;
  height: 60px;
  margin-right: 25px;
  justify-content: space-between;
}
#demo-simple-select-label {
  line-height: 100% !important;
  top: -3px !important;
  font-family: "Inter";
  color: black !important;
  font-size: 15px;
  font-weight: 500;
}
.select_item {
  font-family: "Inter";
  color: black !important;
  font-size: 13px !important;
  font-weight: 500 !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  display: none !important;
}
.MuiSelect-select {
  font-weight: 500 !important;
  padding: 8px 20px !important;
}
.align_center {
  text-align: center;
}
.reports_value {
  background-color: #f9ff9e;
}
.reports_value li {
  font-weight: 700;
}
.midle_line {
  width: 100%;
  height: 1.5px;
  background-color: #0176ee;
  margin: 20px 0;
}
.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
  border-radius: 8px;
  background-color: #fff;
  border: none !important;
}
.date:focus {
  border: none !important;
}
.date {
  width: 120px !important;
  border: 1px solid #3b9afd !important;
  border-radius: 8px !important;
  /* margin: 10px 0 !important; */
}
.css-1pysi21-MuiFormLabel-root-MuiInputLabel-root {
  font-size: 15px !important;
  line-height: normal !important;
  font-weight: 600 !important;
}
.css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
  display: none !important;
}
.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
  font-size: 13px !important;
  font-weight: 500 !important;
  font-family: "Inter" !important;
  border: none !important;
}
.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  display: none !important;
}
.sticky_table {
  position: sticky;
  left: 1px !important;
  height: 100%;
  display: flex;
  align-items: center;
  z-index: 9;
  border-bottom-left-radius: 15px;
  border-top-left-radius: 15px;
  border-right: 1px solid #3b9afd;
  background-color: #fff;
}
.level_up_item {
  display: grid;
  padding-left: 20px !important;
  height: 100%;
  width: 100%;
  font-weight: 500;
  align-items: center;
  grid-template-columns: 0.05fr 1fr 1.3fr;
  gap: 10px;
  /* overflow: hidden; */
}
.level_statistic {
  width: 100% !important;
}
.employee_item {
  display: grid;
  height: 100%;
  width: 100%;
  font-weight: 500;
  align-items: center;
  grid-template-columns: 30px 180px 125px;
  gap: 15px;
  overflow: hidden;
  padding-left: 20px !important;
}
.sticky_build_search_employee {
  padding: 0px;
  margin-left: -20px;
  z-index: 0;
}
.sticky_list {
  padding: 0px !important;
}
.sticky_list_employee {
  padding: 0px;
  padding-right: 18px !important;
}
.sticky_build_search {
  padding: 0px !important;
}
.list_padding_sticky {
  text-align: center;
  padding: 15px 0px !important;
}
.sticky_padding {
  padding: 22px 0 !important;
  text-align: center;
}
@media (max-width: 1920px) {
  .card {
    width: 300px;
  }
  .modal-select select.modal-input {
    padding: 11px 40px 11px 11px !important;
  }
  .students_modal_scroll {
    max-height: auto !important;
  }
  .modal-input {
    padding: 10px;
    /* margin: 8px 0 15px 0 !important; */
  }
  .title_bar_item {
    margin: 0 25px !important;
    align-items: center;
  }
  .title_bar_btns {
    align-items: center;
    margin: 0 25px !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .modal_rooms_active {
    display: flex !important;
    max-height: 350px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 450px !important;
  }
  .rooms_block {
    padding: 10px;
    width: 31%;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0;
    border-radius: 8px;
    margin-right: 33px;
    margin-bottom: 20px;
  }
  .rooms_block:nth-child(3n) {
    margin-right: 0 !important;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .modal-rooms {
    position: relative;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
}
@media (max-width: 1600px) {
  .card {
    width: 250px;
  }
  /* Input Error */

  .input-error-message {
    font-size: 12px !important;
    color: rgb(238, 81, 81) !important;
    bottom: 0px;
  }
  /* Branch Rooms */

  .title_bar_item {
    margin: 0 !important;
    margin-right: 25px !important;
  }
  .title_bar_btns {
    margin: 0 !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .modal_rooms_active {
    display: flex !important;
    max-height: 290px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 390px !important;
  }
  .rooms_block {
    padding: 10px;
    width: 31%;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0;
    border-radius: 8px;
    margin-right: 33px;
    margin-bottom: 20px;
  }
  .rooms_block:nth-child(3n) {
    margin-right: 0 !important;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .room_name {
    margin: 0 !important;
  }
}
@media (max-width: 1400px) {
  .textbook-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
  .textbook-card {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #fff;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .textbook-card:hover {
    border: 1px solid #0176ee;
  }
  .textbook-card__title {
    font-size: 18px;
    font-weight: 500;
  }
  .textbook-card img {
    height: 80px;
    width: 80px;
  }
  .sticky_padding {
    padding: 22px 0;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
  .modal_rooms_active {
    display: flex !important;
    max-height: 270px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 390px !important;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .room_name {
    margin: 0 !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .rooms_block {
    padding: 10px;
    width: 31%;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0 !important;
    border-radius: 8px;
    margin-right: 28px !important;
    margin-bottom: 20px !important;
  }
  .rooms_block:nth-child(3n) {
    margin-right: 0 !important;
  }
  .sticky_table {
    position: sticky;
    left: 0.5px !important;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 9;
    background-color: #fff;
  }
  .level_up_item {
    display: grid;
    height: 100%;
    width: 100%;
    align-items: center;
    grid-template-columns: 30px 100px 140px;
    gap: 15px !important;
  }
}
@media (max-width: 1200px) {
  .textbook-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 20px;
  }
  .textbook-card {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #fff;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .textbook-card:hover {
    border: 1px solid #0176ee;
  }
  .textbook-card__title {
    font-size: 18px;
    font-weight: 500;
  }
  .textbook-card img {
    height: 80px;
    width: 80px;
  }

  .attendanceDiscountModal {
    min-width: 0 !important;
  }
  .get_above {
    position: absolute !important;
    top: 20px !important;
    left: 25% !important;
    z-index: 99;
  }
  .modal_rooms_active {
    display: flex !important;
    max-height: 290px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 390px !important;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .room_name {
    margin: 0 !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .rooms_block {
    padding: 10px;
    width: 31%;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0 !important;
    border-radius: 8px;
    margin-right: 27px !important;
    margin-bottom: 20px !important;
  }
  .rooms_block:nth-child(3n) {
    margin-right: 0 !important;
  }
}
@media (max-width: 1100px) {
  .modal_rooms_active {
    display: flex !important;
    max-height: 290px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 390px !important;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .room_name {
    margin: 0 !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .rooms_block {
    padding: 10px;
    width: 31%;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0 !important;
    border-radius: 8px;
    margin-right: 20px !important;
    margin-bottom: 20px !important;
  }
  .rooms_block:nth-child(3n) {
    margin-right: 0 !important;
  }
}
@media (max-width: 950px) {
  .textbook-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 20px;
  }
  .textbook-card {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #fff;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .textbook-card:hover {
    border: 1px solid #0176ee;
  }
  .textbook-card__title {
    font-size: 18px;
    font-weight: 500;
  }
  .textbook-card img {
    height: 80px;
    width: 80px;
  }

  .home-logo {
    height: 45px;
  }
  .modal_rooms_active {
    display: flex !important;
    max-height: 290px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 390px !important;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .room_name {
    margin: 0 !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .rooms_block {
    padding: 10px;
    width: 31%;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0 !important;
    border-radius: 8px;
    margin-right: 17px !important;
    margin-bottom: 20px !important;
  }
  .rooms_block:nth-child(3n) {
    margin-right: 0 !important;
  }
}
@media (max-width: 740px) {
  .reports-table th,
  .reports-table td,
  .reports-table-footer td {
    padding: 5px;
    font-size: 10px;
    border: 1px solid #d1d5db;
  }
  .payment-place {
    gap: 15px;
  }
  .search-id__wrapper {
    padding: 18px;
    row-gap: 15px;
  }
  .transfer-info_huge {
    padding: 18px;
  }
  .expenditure-input {
    width: 100%;
  }
  .cash-disount__details-item {
    min-width: 80px;
  }
  .transfer-info {
    gap: 15px;
  }
  .cash-disount__list {
    width: fit-content !important;
  }
  .cash-disount__details {
    min-width: 100% !important;
    width: max-content;
    max-width: 100% !important;
  }
  .number--payment {
    min-width: 10px !important;
  }
  .transfer-student-select {
    width: 100%;
  }
  .transfer-select {
    width: 100%;
  }
  .tab_panel {
    width: 100% !important;
    padding: 0 !important;
  }
  .tab_panel .css-19kzrtu {
    padding: 0 !important;
  }
  .d-none {
    display: none !important;
  }
  .data_table {
    flex-direction: column;
  }
  /* StudentsRegistration */

  .student--dicount_place {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0;
    margin-top: 0 !important;
  }
  .registir-discount__type {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 10px 10px 5px 0;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin: 10px 0;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    -o-border-radius: 5px;
  }

  .profileDataTable {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border: 1px solid #ccc;
    margin: 10px 0;
  }
  .staffPay {
    display: flex;
    justify-content: space-between;
  }
  .textBookTitle {
    font-size: 15px;
    font-weight: 600;
    margin: 10px 0;
  }
  .textBookTitle span {
    color: #eda202;
  }
  .textbooks {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
  }
  .personalInfo {
    display: grid;
    color: #41424d;
    grid-template-columns: 1fr;
    gap: 20px;
    padding: 10px 15px;
    border-top-left-radius: 10px;
    background: #fff;
  }
  .personalInfo .transfer-infos {
    font-size: 13px;
  }
  .profile-photo-container {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 100px;
  }
  .profile-photo-container input[type="file"] {
    display: none;
  }
  .profile-photo {
    width: 100px;
    border: 2px solid #929090;
    height: 100px;
    border-radius: 10px;
    object-fit: cover;
    background-color: #f1f1f1;
    border-radius: 50%;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
  }

  .inputLabel {
    font-size: 13px;
  }
  .profileContainer {
    position: sticky;
    width: 100%;
    border: 2px solid #c9c9c9;
    max-width: 1600px;
    margin: auto;
    border-radius: 10px;
    min-height: calc(90vh - 50px);
    margin-top: 10px !important;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .profileLeft {
    order: 2;
    width: 100%;
    height: auto;
    background-color: #fff !important;
  }
  .profileRight {
    order: 1;
    width: 100%;
    border-left: none;
    border-bottom: 2px solid #c9c9c9;
    margin-bottom: 10px;
    height: auto;
  }
  .passwordContainer {
    padding: 10px;
  }

  .students_modal_scroll {
    overflow-y: auto !important;
    max-height: 370px !important;
  }
  .modal_rooms_active {
    display: flex !important;
    max-height: 290px !important;
  }
  .modal_rooms_active_height {
    display: flex !important;
    max-height: 390px !important;
  }
  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .autoselect_room {
    width: 100%;
  }
  .room_number {
    width: 25%;
    font-family: "Inter" !important;
    margin: 0 !important;
    font-weight: 600;
    color: #0176ee;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px !important;
  }
  .room_number::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
  }
  .room_name {
    margin: 0 !important;
  }
  .modal_rooms {
    display: none;
    background: #f2f3f5;
    flex-wrap: wrap;
    padding: 20px 30px;
    padding-bottom: 0 !important;
    max-height: 0px;
    overflow-y: auto;
    margin: 15px 0;
    border: 1px solid #429ffe;
    border-radius: 8px;
    transition: max-height 0.3s linear !important;
  }
  .rooms_block {
    padding: 10px;
    width: 100% !important;
    border: 1px solid #3b9afd;
    background: #fff;
    margin: 0 !important;
    border-radius: 8px;
    margin-bottom: 15px !important;
  }
  .rooms_block:nth-child(2n) {
    margin-right: 0 !important;
  }
  .sticky_padding {
    padding: 22px 0;
  }
  .sticky_table {
    position: sticky;
    left: 0.4px !important;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 9;
    background-color: #fff;
  }
  .level_up_item {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: 10px 70px 100px;
    gap: 15px;
  }
  .back_btn {
    padding: 6px 20px;
    font-size: 12px;
  }
  .title_bar_btns {
    width: 220px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 0px 0;
  }
  .enter {
    margin: 0px !important;
  }
}
@media (max-width: 660px) {
  .billing-select {
    width: 100% !important;
  }
  .build-export__number {
    display: flex;
    align-items: center;
  }
  .info-module {
    height: 30px;
  }
  .input-error-message {
    position: relative !important;
  }
  .textbook-list {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  .textbook-card {
    min-height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 10px;
    padding: 10px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid #fff;
    cursor: pointer;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
  }
  .textbook-card:hover {
    border: 1px solid #0176ee;
  }
  .textbook-card__title {
    font-size: 18px;
    font-weight: 500;
  }
  .textbook-card img {
    height: 60px;
    width: 60px;
  }

  .trello-modal {
    width: 100%;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    -o-border-radius: 0;
  }
  .smooth-dnd-container {
    display: flex !important;
    flex-direction: column !important;
  }
  .react-trello-board {
    display: flex;
    justify-content: center;
  }
  .attendanceDiscountModal {
    max-width: 100%;
    font-size: 11px;
    padding: 15px;
  }
  .discountContainer {
    min-width: 0;
  }
  .discountContainer label {
    font-size: 11px;
  }
  .group_modal_icon h4 {
    font-size: 12px;
  }
  .group_modal_icon {
    padding: 3px 15px !important;
  }
  .group_modal_hidden {
    max-height: 35px !important;
  }
  .page-title {
    padding: 5px;
    font-size: 11px;
  }
  .build-title {
    flex-direction: column;
    margin: 10px 0;
  }
  .title_bar_item {
    order: 2;
  }
  .title_bar_btns {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    order: 1;
    gap: 10px 0;
  }
  .build-top__item {
    position: relative;
    margin: 0;
  }
  .filter {
    height: 30px !important;
    font-size: 11px;
    padding: 0;
  }
  .search-box {
    padding: 8px 12px 8px 30px;
    height: 30px !important;
    background: #f6f6f6;
    border: 1px solid #cccccc;
    border-radius: 4px;
    font-size: 11px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    -ms-border-radius: 4px;
    -o-border-radius: 4px;
  }
  .home-logo {
    height: 40px;
  }
  .payment-place {
    flex-direction: column;
  }
  .payment-input {
    width: 70% !important;
    margin-bottom: 20px !important;
  }
  .income__save--btn {
    min-width: 130px !important;
  }
  .income-searchbar {
    position: relative;
    width: 100%;
    min-height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }
  .icome-student_short_lists {
    position: initial;
    width: 100%;
    z-index: 1;
    border: 1px solid #ccc;
    border-radius: 8px;
    -webkit-border-radius: 8px;
    -moz-border-radius: 8px;
    -ms-border-radius: 8px;
    -o-border-radius: 8px;
    min-height: 40px;
    max-width: 300px;
    overflow: hidden;
  }
  .search-id_input {
    width: 300px;
    height: 40px;
    border: none;
    margin: 0 !important;
  }
  .search-id_input:focus {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px,
      rgba(0, 0, 0, 0.1) 0px 0px 1px 0px !important;
  }
  .payment--grid {
    grid-template-columns: 0.05fr 0.7fr 0.5fr repeat(3, 0.5fr) 0.7fr 0.7fr 0.4fr 0.4fr 0.4fr 0.2fr;
    gap: 0 20px;
    text-align: center !important;
    width: 100%;
  }
  .income-reports--grid {
    grid-template-columns: 0.05fr 0.7fr 0.5fr repeat(3, 0.5fr) 0.7fr 0.7fr 0.4fr 0.4fr 0.4fr;
    gap: 0 20px;
    text-align: center !important;
    width: 100%;
  }
  .debet--grid {
    grid-template-columns: 20px 100px 80px repeat(3, 80px) 100px 100px 80px;
    gap: 0 20px;
    text-align: center !important;
    width: 100%;
  }
  .exam_table_head {
    width: max-content;
    display: grid;
    align-items: center;
    grid-template-columns: 0.05fr 0.3fr 0.3fr 0.4fr 0.6fr 0.6fr repeat(4, 0.4fr) 0.05fr;
    gap: 0 20px;
  }
  .sticky_padding {
    padding: 22px 0 !important;
  }
  .input-error-message {
    font-size: 11px !important;
    color: rgb(238, 81, 81) !important;
    bottom: 0px !important;
  }
  .back_btn {
    padding: 6px 18px;
    font-size: 12px;
  }
  .sticky_table {
    position: sticky;
    left: 0.1px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 9;
    background-color: #fff;
  }
  .level_up_item {
    display: grid;
    height: 100%;
    align-items: center;
    grid-template-columns: 10px 60px 80px;
    gap: 15px;
  }
}
@media (max-width: 400px) {
  .content-item .sticky_padding {
    padding: 12px 0 !important;
  }
  .sticky_table {
    position: sticky;
    left: 0.1px;
    height: 100%;
    display: flex;
    align-items: center;
    z-index: 9;
    background-color: #fff;
  }
  .level_up_item {
    display: grid;
    height: 100%;
    width: 100% !important;
    align-items: center;
    grid-template-columns: 10px 60px 80px;
    gap: 15px;
  }
}
@media (min-width: 1920px) {
  .sticky_list_employee {
    padding: 0px 12px !important;
  }
  /* .enter {
    margin-top: 0;
  } */
}
